import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';

const Error = () => (
	<Layout pageType="404">
		<Seo title='Error | Jazz Webinars' description='' />
 
		<section className='container error'>
			<p className='header'>Error 404. Page not found.</p>
            <p className='copy md-fontsize'>It looks like this page doesn't exist. Why not visit our <a href='/' className='homepageLink'>homepage</a> instead?</p>
		</section>
	</Layout>
);

export default Error;
